import { Block } from 'webiq.boilerplate'

export default class MobileFilter extends Block {
	static title = 'mobile-filter'

	init() {
		this.filterSteps = Array.from(this.element.querySelectorAll('.mobile-filter__step'))
		this.setStepButtons = Array.from(this.element.querySelectorAll('.mobile-filter__step-link'))
		this.openFilterButtons = Array.from(document.querySelectorAll('.mobile-filter__open'))
		this.tags = Array.from(this.element.querySelectorAll('.mobile-filter__item__tags'))

		this.closeButton = this.element.querySelector('.mobile-filter__button--close')
		this.applyButton = this.element.querySelector('.mobile-filter__button--apply')

		this.closeButton.addEventListener('click', () => this.toggleVisibility())

		this.setStepButtons.forEach(stepButton => {
			stepButton.addEventListener('click', () => this.setActiveStep(stepButton))
		})

		this.openFilterButtons.forEach(button => {
			button.addEventListener('click', () => this.toggleVisibility())
		})

		this.updateSelectedView()
	}

	setActiveStep(element) {
		if (!element.hasAttribute('data-step-name')) {
			return
		}

		const stepName = element.getAttribute('data-step-name')

		this.filterSteps.forEach(filterStep => {
			if (filterStep.getAttribute('data-step-name') == stepName) {
				filterStep.classList.add('mobile-filter__step--active')
				return
			}

			filterStep.classList.remove('mobile-filter__step--active')
		})

		this.updateSelectedView()
		this.toggleApplyButton()
	}

	toggleApplyButton() {
		let showApplyButton = false

		this.tags.forEach(tag => {
			tag.innerText.length > 0 ? (showApplyButton = true) : null
		})

		//show
		if (showApplyButton) {
			this.closeButton.classList.add('mobile-filter__button--hidden')
			this.applyButton.classList.remove('mobile-filter__button--hidden')
			return
		}

		this.applyButton.classList.add('mobile-filter__button--hidden')
		this.closeButton.classList.remove('mobile-filter__button--hidden')
	}

	toggleVisibility() {
		this.element.classList.toggle('mobile-filter--hidden')
	}

	updateSelectedView() {
		const selectedBrands = Array.from(this.element.querySelectorAll('input[name="filters[brand_id][]"]:checked')),
			selectedSizes = Array.from(this.element.querySelectorAll('input[name="filters[size_id][]"]:checked')),
			selectedColors = Array.from(this.element.querySelectorAll('input[name="filters[color_group_id][]"]:checked'))

		let brands = '',
			sizes = '',
			colors = ''

		selectedBrands.forEach(brand => {
			brands += `${brand.getAttribute('data-name')}, `
		})
		this.element.querySelector('.mobile-filter__item__tags[data-filter-type="brand"]').innerText = brands.substring(
			0,
			brands.length - 2
		)

		selectedSizes.forEach(size => {
			sizes += `${size.getAttribute('data-name')}, `
		})
		this.element.querySelector('.mobile-filter__item__tags[data-filter-type="size"]').innerText = sizes.substring(
			0,
			sizes.length - 2
		)

		selectedColors.forEach(color => {
			colors += `${color.getAttribute('data-name')}, `
		})
		this.element.querySelector('.mobile-filter__item__tags[data-filter-type="color"]').innerText = colors.substring(
			0,
			colors.length - 2
		)
	}
}
