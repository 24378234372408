import { Block } from 'webiq.boilerplate'

export default class ProductSet extends Block {
	static title = 'product-set'

	init() {
		this.sizesDropdowns = Array.from(this.element.querySelectorAll('.product-set__option__size-dropdown'))
		this.colorsDropdown = this.element.querySelector('.product-set__option__color-dropdown')
		this.wishlist = this.element.querySelector('.wishlist')

		this.colorsDropdown.addEventListener('change', () => this.colorDropdownChange())
		this.sizesDropdowns.forEach(sizeDropdown => {
			sizeDropdown.addEventListener('change', () => this.sizeDropdownChange(sizeDropdown))
		})
	}

	colorDropdownChange() {
		const selectedColor = this.colorsDropdown.value,
			sizeDropdownIdentifier = `${this.element.getAttribute('data-product-id')}_${selectedColor}`

		let sizeDropdown = this.element.querySelector(
			`.product-set__option__size-dropdown[data-size-for="${sizeDropdownIdentifier}"]`
		)

		this.sizesDropdowns.forEach(sizeDropdown => {
			if (sizeDropdown.getAttribute('data-size-for') === sizeDropdownIdentifier) {
				sizeDropdown.disabled = false
				sizeDropdown.classList.remove('product-set__option__size-dropdown--hidden')
				return
			}

			sizeDropdown.disabled = true
			sizeDropdown.classList.add('product-set__option__size-dropdown--hidden')

			if (this.wishlist) {
				this.wishlist.querySelector('.wishlist__color-value').value = ''
				this.wishlist.querySelector('.wishlist__size-value').value = ''
			}
		})
	}

	sizeDropdownChange(dropdown) {
		let option = dropdown.options[dropdown.selectedIndex]
		if (this.wishlist) {
			this.wishlist.querySelector('.wishlist__color-value').value = option.getAttribute('data-color-id')
			this.wishlist.querySelector('.wishlist__size-value').value = option.getAttribute('data-size-id')
		}
	}
}
