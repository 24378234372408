import { Block } from 'webiq.boilerplate'

export default class SectionToggle extends Block {
	static title = 'section-toggle'

	init() {
		this.sections = Array.from(this.element.querySelectorAll('.section-toggle__section'))
		this.toggles = Array.from(this.element.querySelectorAll('.section-toggle__toggle'))

		this.toggles.forEach(toggleButton => {
			toggleButton.addEventListener('click', () => this.toggleSections())
		})
	}

	toggleSections() {
		this.sections.forEach(section => {
			section.classList.toggle('section-toggle__section--active')
		})

		this.toggles.forEach(toggleButton => {
			toggleButton.classList.toggle('section-toggle__toggle--active')
		})
	}
}
