import { Block } from 'webiq.boilerplate'

export default class AccountRegister extends Block {
	static title = 'account-register'

	init() {
		this.collapsers = Array.from(this.element.querySelectorAll('.account-register__collapser'))
		this.registerContent = this.element.querySelector('.account-register__content')

		this.collapsers.forEach(collapser => {
			collapser.addEventListener('change', () => {
				// show the register form
				this.registerContent.classList.remove('account-register__content--hidden')
				let guest = collapser.classList.contains('account-register__collapser--guest')
				Array.from(this.element.querySelectorAll('[data-guest-hidden]')).forEach(el => {
					if (guest) {
						el.classList.add('form__input--hidden')
						el.disabled = true
						el.value = ''
						return
					}
					el.classList.remove('form__input--hidden')
					el.disabled = false
				})
			})
		})
	}
}
