import { Block } from 'webiq.boilerplate'
import ReturnPopup from './ReturnPopup'

export default class ProductOrder extends Block {
	static title = 'product-order'

	init() {
		this.returnButton = this.element.querySelector('.product-order__button--return')
		this.returnPopup = document.querySelector('.account-popup--return')
		if (this.returnPopup) {
			this.returnPopupContent = this.returnPopup.querySelector('.account-popup__content')
		}

		this.overlay = document.querySelector('.overlay--main')

		if (this.returnButton && this.returnPopup) {
			this.returnButton.addEventListener('click', () => this.returnPopupStart())
		}

		this.input = this.element.querySelector('.product-order__number__input')
		this.plus = this.element.querySelector('.product-order__number__button[data-plus]')
		this.min = this.element.querySelector('.product-order__number__button[data-min]')

		if (this.input) {
			this.plus.addEventListener('click', () => {
				this.input.value = Math.min(parseInt(this.input.value) + 1, this.input.max)
			})

			this.min.addEventListener('click', () => {
				this.input.value = Math.max(parseInt(this.input.value) - 1, this.input.min || 0)
			})
		}
	}

	returnPopupStart() {
		let payload = new FormData()
		payload.append('order_id', this.returnButton.getAttribute('data-order-id'))
		payload.append('product_id', this.returnButton.getAttribute('data-product-id'))
		payload.append('ajax', true)
		payload.append('return_step_one', true)

		fetch('/account', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.handleReturnPopupOpen(json))
	}

	handleReturnPopupOpen(json) {
		this.returnPopupContent.innerHTML = json.html

		this.returnPopup.classList.remove('account-popup--hidden')
		this.overlay.classList.remove('overlay--hidden')

		this.returnPopupClass = new ReturnPopup(this.returnPopup, this.eventEmitter)
		this.returnPopupClass.init()
	}
}
