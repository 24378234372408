import { Block } from 'webiq.boilerplate'

export default class Message extends Block {
	static title = 'message'

	constructor(element) {
		super(element)

		if (this.element.querySelector('.message__close')) {
			this.element.querySelector('.message__close').addEventListener('click', () => this.hide())
		}
	}

	hide() {
		this.element.classList.remove('message--visible')
	}
}
