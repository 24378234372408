import { Block } from 'webiq.boilerplate'

export default class SetProducts extends Block {
	static title = 'set'

	init() {
		this.products = Array.from(this.element.querySelectorAll('[name="products[]"]'))
		this.addProductsButton = this.element.querySelector('.set__button--add-to-cart')
		this.messageBox = this.element.querySelector('.message--shoppingcart')

		this.addProductsButton.addEventListener('click', () => this.formSubmit())
	}

	formSubmit() {
		let productsToAdd = []

		this.products.forEach(product => {
			if (product.value) {
				productsToAdd.push(product.value)
			}
		})

		let payload = new FormData()
		for (var i = 0; i < productsToAdd.length; i++) {
			payload.append('products[]', productsToAdd[i])
		}
		payload.append('ajax', true)
		payload.append('add_set', true)

		fetch('/shoppingcart', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.handleResponse(json))
	}

	handleResponse(json) {
		if (json.error) {
			this.messageBox.querySelector('.message__content').innerText = json.error
			this.messageBox.classList.add('message--error')
			this.messageBox.classList.remove('message--success')
			this.messageBox.classList.add('message--visible')
			return
		}

		this.messageBox.querySelector('.message__content').innerText = json.message
		this.messageBox.classList.remove('message--error')
		this.messageBox.classList.add('message--success')
		this.messageBox.classList.add('message--visible')
	}
}
