import { Block } from 'webiq.boilerplate'
import ProductOrder from './ProductOrder'

export default class ReturnPopup extends Block {
	static title = 'account-popup--return'

	init() {
		this.overlay = document.querySelector('.overlay--main')
		this.closeButton = this.element.querySelector('.account-popup__close')
		if (this.closeButton) {
			this.closeButton.addEventListener('click', () => this.hidePopup())
		}

		this.popupTitle = this.element.querySelector('.account-popup__title')

		this.returnPopupContent = this.element.querySelector('.account-popup__content')

		this.productCountSelects = Array.from(this.element.querySelectorAll('.product-order'))

		this.stepOneForm = this.element.querySelector('.account-popup--return__form--step-1')

		this.stepOneForm.addEventListener('submit', e => this.submitStepOne(e))

		this.productCountSelects.forEach(productOrder => {
			productOrder.ref = new ProductOrder(productOrder, this.eventEmitter)
			productOrder.ref.init()
		})

		this.eventEmitter.on('overlay:close', () => this.hidePopup())

		this.bindButtons()
	}

	submitStepOne(e) {
		e.preventDefault()

		let payload = new FormData(this.stepOneForm)
		payload.append('ajax', true)
		payload.append('return_step_two', true)

		fetch('/account', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.stepTwo(json))
	}

	stepTwo(json) {
		this.returnPopupContent.innerHTML = json.html
		this.setPopupTitle(json.title)

		this.bindButtons()
		this.stepTwoForm = this.element.querySelector('.account-popup--return__form--step-2')
		this.stepTwoForm.addEventListener('submit', e => this.submitStepTwo(e))
	}

	submitStepTwo(e) {
		e.preventDefault()

		let payload = new FormData(this.stepTwoForm)
		payload.append('ajax', true)
		payload.append('return_step_three', true)

		fetch('/account', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.stepThree(json))
	}

	setPopupTitle(title) {
		if (this.popupTitle) {
			this.popupTitle.innerText = title
		}
	}

	stepThree(json) {
		this.returnPopupContent.innerHTML = json.html
		this.setPopupTitle(json.title)

		this.bindButtons()

		this.stepBackButton = document.querySelector('.account-popup__button--return__previous-step-2')
		this.stepBackButton.addEventListener('click', e => this.submitStepOne(e))
	}

	bindButtons() {
		Array.from(document.querySelectorAll('.account-popup--return__button--cancel')).forEach(button => {
			button.addEventListener('click', () => this.hidePopup())
		})
	}

	hidePopup() {
		this.overlay.classList.add('overlay--hidden')
		this.element.classList.add('account-popup--hidden')
	}
}
