import { Block } from 'webiq.boilerplate'

export default class Orders extends Block {
	static title = 'orders'

	init() {
		this.ordersElements = this.getElements('order--return')
		this.ordersElements.forEach(orderEl => {
			let extraElement = orderEl.querySelector('.orders__order__extra')

			let showExtraButton = orderEl.querySelector('.orders__order__item--show-extra')
			if (showExtraButton) {
				showExtraButton.addEventListener('click', () => this.showExtra(extraElement))
			}

			let hideExtraButton = orderEl.querySelector('.orders__order--hide-extra')
			if (hideExtraButton) {
				let reasonsInputs = orderEl.querySelectorAll('.orders__tag__input--reason')
				hideExtraButton.addEventListener('click', () => this.hideExtra(extraElement, reasonsInputs))
			}
		})
	}

	showExtra(el) {
		if (!el) {
			return
		}

		el.classList.add('orders__order__extra--active')
	}

	hideExtra(el, reasonsInputs = []) {
		if (!el) {
			return
		}

		reasonsInputs.forEach(reasonInput => {
			reasonInput.checked = false
		})

		el.classList.remove('orders__order__extra--active')
	}
}
