import { Block } from 'webiq.boilerplate'

export default class ShoppingcartPopupButton extends Block {
	static title = 'shoppingcart-popup-button'

	init() {
		this.shoppingcartPopup = document.querySelector('.shoppingcart-popup')
		this.countElement = this.element.querySelector('.shop-nav__item__count')

		this.element.addEventListener('click', () => this.toggleShoppingcart())
		this.eventEmitter.on('shoppingcart-popup:update', payload => this.update(payload))
	}

	toggleShoppingcart() {
		if (!this.shoppingcartPopup && !this.shoppingcartPopup.ref) {
			return
		}

		this.shoppingcartPopup.ref.toggle()
	}

	update(payload) {
		if (this.countElement) {
			this.countElement.innerText = payload.count
		}
	}
}
