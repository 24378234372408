import 'babel-polyfill'
import 'cookieconsent'
import './bootstrap.scss'

import run, {
	AccountNav,
	Accordion,
	Carousel,
	Filter,
	MdcSelect,
	MdcTextField,
	MobileNav,
	Nav,
	ShoppingcartPopup,
	Popup,
	Overlay,
	Tabs,
	StoreLocator,
	Form,
	Social,
	HCaptcha,
	ShoppingcartProduct,
	ScrollButton,
	ShoppingcartAddressSection,
} from 'webiq.boilerplate'

import CustomProductInfo from './js/CustomProductInfo'
import SetOverview from './js/SetOverview'
import SectionToggle from './js/SectionToggle'
import PaymentMethod from './js/PaymentMethod'
import MobileFilter from './js/MobileFilter'
import AccountRegister from './js/AccountRegister'
import ShoppingcartPopupButton from './js/ShoppingcartPopupButton'
import Search from './js/Search'
import ImageGallery from './js/ImageGallery'
import ProductOrder from './js/ProductOrder'
import PopupProjector from './js/PopupProjector'
import Orders from './js/Orders'
import CustomWishlist from './js/CustomWishlist'

// new shoppingcart
import CustomAddress from './js/CustomAddress'

run({
	AccountNav,
	HCaptcha,
	Accordion,
	Carousel,
	Filter,
	MdcSelect,
	MdcTextField,
	MobileNav,
	Search,
	Nav,
	ShoppingcartPopup,
	CustomProductInfo,
	SetOverview,
	Popup,
	Orders,
	Overlay,
	Tabs,
	StoreLocator,
	Form,
	ImageGallery,
	Social,
	ProductOrder,

	MobileFilter,
	AccountRegister,
	ShoppingcartPopupButton,

	ShoppingcartProduct,
	SectionToggle,
	PaymentMethod,
	PopupProjector,
	CustomWishlist,

	ShoppingcartAddressSection,
	CustomAddress,
	ScrollButton,
})
