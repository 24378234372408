import { Block } from 'webiq.boilerplate'

export default class Search extends Block {
	static title = 'search'

	init() {
		if (!this.element.dataset.autocomplete) {
			return
		}

		this.searchInput = this.getElement('input')
		this.searchDropdown = this.getElement('dropdown')
		this.searchTerm = this.searchInput.value

		this.genderSearch = this.element.dataset.gender

		this.typingTimer = null

		this.searchInput.addEventListener('keyup', e => this.toggleDropdown(e))
		// this.searchInput.addEventListener('focusout', (e) => this.hideDropdown(e))

		this.element.parentElement.addEventListener('submit', e => this.submitForm(e))
	}

	submitForm(e) {
		const hasProducts = this.element.querySelector('[data-products]')
		if (!hasProducts || true) {
			e.preventDefault()
			this.searchDropdown.classList.add('search__dropdown--active')
			return
		}
	}

	hideDropdown(e) {
		if (e.keyCode == 13) {
			return
		}

		if (
			(e.relatedTarget && e.relatedTarget.tagName.toLowerCase() === 'a') ||
			(e.target && e.target.tagName.toLowerCase() === 'a')
		) {
			return
		}

		this.searchDropdown.classList.remove('search__dropdown--active')
	}

	toggleDropdown(e) {
		if (e.keyCode == 13) {
			this.searchDropdown.classList.add('search__dropdown--active')
			return
		}

		clearTimeout(this.typingTimer)

		const query = e.target.value

		// Hide dropdown if input field is empty.
		if (query === '' || e.keyCode === 27) {
			this.searchDropdown.classList.remove('search__dropdown--active')
			return
		}

		// should be more than 3 chars
		if (query.length < 3) {
			this.searchDropdown.classList.remove('search__dropdown--active')
			return
		}
		this.searchTerm = query

		this.typingTimer = setTimeout(() => this.getSuggestions(), 500)
	}

	getSuggestions() {
		let url = '/search?autocomplete&term=' + encodeURI(this.searchTerm)
		if (this.genderSearch) {
			url += '&gender=' + this.genderSearch
		}

		fetch(url, {
			method: 'POST',
			credentials: 'same-origin',
		})
			.then(response => response.json())
			.then(json => this.handleResponse(json))
	}

	handleResponse(json) {
		this.searchDropdown.innerHTML = json.html
		this.searchDropdown.classList.add('search__dropdown--active')

		document.addEventListener('click', e => {
			this.hideDropdown(e)
		})

		document.addEventListener('touchend', e => {
			this.hideDropdown(e)
		})
	}
}
