import { Popup } from 'webiq.boilerplate'

export default class ProjectorPopup extends Popup {
	static title = 'popup-projector'

	init() {
		this.scrollable = this.hasModifier('scrollable')

		this.closeButton = this.getElement('close')
		if (this.closeButton) {
			this.closeButton.addEventListener('click', () => this.close())
		}

		this.overlay = document.querySelector('.overlay--projector')
		if (this.overlay) {
			this.overlay.addEventListener('click', () => this.close())
		}

		this.projectorButton = this.getElement('button--projector')
		if (this.projectorButton) {
			this.projectorButton.addEventListener('click', () => this.project())
		}

		this.messageBox = document.querySelector('.message--projector')
	}

	project() {
		if (!this.element.getAttribute('data-product-id')) {
			return
		}

		let url = `/projector/product/request?product_id=${this.element.getAttribute('data-product-id')}`

		fetch(url, {
			method: 'GET',
			credentials: 'same-origin',
		})
			.then(response => response.json())
			.then(json => this.handleProjectResponse(json))
	}

	handleProjectResponse(json) {
		this.close()

		console.log(this.messageBox)
		if (!this.messageBox) {
			return
		}

		this.messageBox.innerText = json.message
		this.messageBox.classList.add('message--visible')
	}
}
