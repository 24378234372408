import { Block } from 'webiq.boilerplate'

export default class PaymentMethod extends Block {
	static title = 'payment-method'

	init() {
		this.paymentButtons = document.querySelectorAll('.button--payment-method')
		this.element.addEventListener('click', e => this.toggle(e), false)
	}

	toggle(event) {
		Array.from(document.querySelectorAll('.payment-method')).forEach(element => {
			element.classList.remove('payment-method--selected')
		})

		this.element.classList.add('payment-method--selected')

		const paymentMethod = event.target.getAttribute('data-payment-method-name')
		let button = document.querySelector('.button--default-payment')

		if (document.querySelector(`.button--${paymentMethod}`)) {
			button = document.querySelector(`.button--${paymentMethod}`)
		}

		this.paymentButtons.forEach(paymentButton => {
			if (button == paymentButton) {
				paymentButton.classList.remove('button--hidden')
				return
			}

			paymentButton.classList.add('button--hidden')
		})
	}
}
