import { Block } from 'webiq.boilerplate'
import Message from './CustomMessage'
import Wishlist from './CustomWishlist'
import ProductSet from './ProductSet'
import SetProducts from './Set'
import { Popup } from 'webiq.boilerplate'

export default class SetOverview extends Block {
	static title = 'set-overview'

	init() {
		this.setLinks = Array.from(this.element.querySelectorAll('.set-overview__set__link'))
		this.setPopup = document.querySelector('.popup--set')
		this.overlay = document.querySelector('.overlay--main')

		this.setLinks.forEach(setLink => {
			setLink.addEventListener('click', () => this.getSetInfo(setLink.getAttribute('data-set-id')))
		})
	}

	getSetInfo(id) {
		let payload = new FormData()
		payload.append('set_id', id)
		payload.append('set_info', true)
		payload.append('ajax', true)

		fetch('/set', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.showSet(json))
	}

	showSet(json) {
		this.setPopup.querySelector('.popup__content').innerHTML = json.html

		// init all blocks
		Array.from(this.setPopup.querySelectorAll('.wishlist')).forEach(block => {
			block.ref = new Wishlist(block, this.eventEmitter)
			block.ref.init()
		})

		Array.from(this.setPopup.querySelectorAll('.product-set')).forEach(block => {
			block.ref = new ProductSet(block, this.eventEmitter)
			block.ref.init()
		})

		Array.from(this.setPopup.querySelectorAll('.set')).forEach(block => {
			block.ref = new SetProducts(block, this.eventEmitter)
			block.ref.init()
		})

		Array.from(this.setPopup.querySelectorAll('.message')).forEach(block => {
			new Message(block)
		})

		this.setPopup.ref = new Popup(this.setPopup, this.eventEmitter)
		this.setPopup.ref.init()

		this.overlay.classList.remove('overlay--hidden')
		this.setPopup.classList.remove('popup--hidden')
	}
}
