import { Block } from 'webiq.boilerplate'

const USA_COUNTRY_ID = '226'

export default class Address extends Block {
	static title = 'address'

	init() {
		this.countrySelect = this.element.querySelector('[name="country_id"]')
		this.stateSelect = this.element.querySelector('[name="state_id"]')

		this.autoCompleteFields = this.element.querySelectorAll('[name="zipcode"], [name="number"]')
		this.zipcodeInput = this.element.querySelector('[name="zipcode"]')
		this.houseNumberInput = this.element.querySelector('[name="number"]')
		this.streetInput = this.element.querySelector('[name="street"]')
		this.cityInput = this.element.querySelector('[name="city"]')

		this.messageBlock = this.element.querySelector('.message--address')

		if (this.element.hasAttribute('check-usa')) {
			this.requireStateId()
		}

		if (this.element.hasAttribute('data-autocomplete')) {
			this.autocompleteAddress(this.element.getAttribute('data-autocomplete'))
		}

		this.closeButton = this.getElement('close')
		if (this.closeButton) {
			this.closeButton.addEventListener('click', () => this.toggle())
		}
	}

	toggle() {
		this.toggleModifier('hidden')
	}

	requireStateId() {
		if (!this.countrySelect.value || !this.stateSelect.value) {
			return
		}

		this.countrySelect.addEventListener('change', () => {
			if (this.countrySelect.options[this.countrySelect.selectedIndex].value === USA_COUNTRY_ID) {
				this.stateSelect.classList.remove('select--hidden')
				this.stateSelect.required = true
				return
			}

			this.stateSelect.classList.add('select--hidden')
			this.stateSelect.required = false
		})
	}

	autocompleteAddress(countryId) {
		if (!this.countrySelect && !this.element.dataset.noCountrySelect) {
			return
		}

		this.autoCompleteFields.forEach(field => {
			field.addEventListener('change', () => {
				if (this.countrySelect && this.countrySelect.value !== countryId) {
					return
				}

				if (!this.zipcodeInput.value || !this.houseNumberInput.value) {
					return
				}

				this.getAutocompletedAddress(this.zipcodeInput.value, this.houseNumberInput.value)
			})
		})
	}

	getAutocompletedAddress(zipcode, houseNumber) {
		const payload = new FormData()
		payload.append('zipcode', zipcode)
		payload.append('number', houseNumber)
		payload.append('validate', '1')

		fetch('/zipcode', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
		})
			.then(response => response.json())
			.then(json => {
				this.fillAutocompleteFields(json)
			})
			.catch(error => this.autofillError(error))
	}

	fillAutocompleteFields(obj) {
		if (obj.error) {
			this.autofillError(obj.error)
		}

		if (!this.streetInput || !this.cityInput || !obj.result) {
			return
		}

		this.toggleMessageBlock(false)

		this.streetInput.value = obj.result.street
		this.streetInput.parentElement.ref.floatLabel(true)
		this.cityInput.value = obj.result.city
		this.cityInput.parentElement.ref.floatLabel(true)
	}

	autofillError(error) {
		if (!this.messageBlock || !error) {
			return
		}

		this.messageBlock.textContent = error
		this.toggleMessageBlock(true)
	}

	toggleMessageBlock(state = null) {
		if (!this.messageBlock) {
			return
		}

		if (state === null) {
			this.messageBlock.classList.toggle('message--active')
			this.messageBlock.classList.toggle('message--hidden')
			return
		}

		if (state === true) {
			this.messageBlock.classList.add('message--active')
			this.messageBlock.classList.remove('message--hidden')
			return
		}

		this.messageBlock.classList.remove('message--active')
		this.messageBlock.classList.add('message--hidden')
	}
}
