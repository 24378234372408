import { Block } from 'webiq.boilerplate'

export default class CustomWishlist extends Block {
	static title = 'wishlist'

	init() {
		this.icon = this.element.querySelector('.wishlist__icon')
		this.messageBox = document.querySelector('.message--wishlist')
		this.submitButton = this.element.querySelector('.wishlist--add-button')
		this.wishlistAddedInput = this.element.querySelector('.wishlist__added-value')
		this.colorIdInput = this.element.querySelector('[name="color_id"]')
		this.sizeIdInput = this.element.querySelector('[name="size_id"]')
		this.productIdInput = this.element.querySelector('[name="product_id"]')

		this.bindEvents()
	}

	setSizeId(sizeId) {
		this.sizeIdInput.value = sizeId
	}

	setColorId(colorId) {
		this.colorIdInput.value = colorId
	}

	addToWishlist(event) {
		event.preventDefault()

		this.setLoading()

		if (this.messageBox) {
			this.messageBox.classList.remove('message--visible')
		}

		if (this.wishlistAddedInput && this.wishlistAddedInput.value !== '0') {
			this.removeFromWishlist()
			return
		}

		const payload = new FormData()
		payload.append('size_id', this.sizeIdInput.value)
		payload.append('color_id', this.colorIdInput.value)
		payload.append('product_id', this.productIdInput.value)
		payload.append('ajax', true)
		payload.append('wishlist_add', true)

		fetch('/account', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				// eslint-disable-next-line
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.handleAddResponse(json))
	}

	removeFromWishlist() {
		const payload = new FormData()
		payload.append('wishlist_product_id', this.element.querySelector('input[name="wishlist_product_id"]').value)
		payload.append('ajax', true)
		payload.append('wishlist_delete', true)

		fetch('/account', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				// eslint-disable-next-line
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.handleRemoveResponse(json))
	}

	handleRemoveResponse(response) {
		this.setRemoved()

		if (response.error && this.messageBox) {
			this.messageBox.querySelector('.message__content').innerText = response.message
			this.messageBox.classList.add('message--error')
			this.messageBox.classList.add('message--visible')
			this.setAdded()
			return
		}

		if (this.wishlistAddedInput) {
			this.wishlistAddedInput.value = 0
		}
	}

	handleAddResponse(response) {
		if ((response.error || !response.wishlist_id) && this.messageBox) {
			this.messageBox.querySelector('.message__content').innerText = response.error
			this.messageBox.classList.add('message--error')
			this.messageBox.classList.add('message--visible')
			this.setRemoved()
			return
		}

		this.setAdded()

		if (this.wishlistAddedInput) {
			this.wishlistAddedInput.value = response.wishlist_id
		}
	}

	setLoading() {
		if (!this.icon) {
			return
		}

		this.icon.classList.remove('fas')
		this.icon.classList.remove('fa-heart')

		this.icon.classList.add('fa-circle-notch')
		this.icon.classList.add('fa-spin')
	}

	setAdded() {
		if (!this.icon) {
			return
		}

		this.icon.classList.add('fa-heart')
		this.icon.classList.add('fas')

		this.icon.classList.remove('fa-spin')
		this.icon.classList.remove('fa-circle-notch')
	}

	setRemoved() {
		if (!this.icon) {
			return
		}

		this.icon.classList.add('fa-heart')
		this.icon.classList.add('fal')

		this.icon.classList.remove('fas')

		this.icon.classList.remove('fa-spin')
		this.icon.classList.remove('fa-circle-notch')
	}

	bindEvents() {
		this.element.addEventListener('click', event => this.addToWishlist(event))
	}
}
