import { Block } from 'webiq.boilerplate'

export default class ProductImageGallery extends Block {
	static title = 'product-image-gallery'

	init() {
		this.thumbnails = Array.from(this.element.querySelectorAll('.product-image--thumbnail'))
		this.mainImage = document.querySelector('.product-image--zoom')

		this.thumbnails.forEach(thumbnail => {
			thumbnail.addEventListener('click', () => {
				if (!thumbnail.dataset.src) {
					return
				}

				this.mainImage.style.backgroundImage = `url('${thumbnail.dataset.src}')`
			})
		})
	}

	viewGallery() {
		this.element.classList.remove('product-image-gallery--hidden')
		this.setMainImage()
	}

	hideGallery() {
		this.element.classList.add('product-image-gallery--hidden')
	}

	setMainImage() {
		if (this.mainImage) {
			this.mainImage.style.backgroundImage = `url('${this.element.getAttribute('data-initial-image') ||
				this.thumbnails[0].dataset.src}')`
		}
	}
}
