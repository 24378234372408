import { Block } from 'webiq.boilerplate'

export default class StockLocationsPopup extends Block {
	static title = 'popup-stock-locations'

	constructor(element, optionId, sizeName, colorName) {
		super(element)

		this.optionId = optionId
		this.sizeName = sizeName
		this.colorName = colorName

		this.interval = null

		this.counter = this.element.querySelector('.popup-stock-locations__countdown')
		this.closeButton = this.element.querySelector('.popup-stock-locations__close')
		this.sizeBox = this.element.querySelector('.popup-stock-location__size-name')

		this.stockLists = Array.from(this.element.querySelectorAll('.popup-stock-locations__option'))

		Array.from(this.element.querySelectorAll('.popup__close')).forEach(closeButton => {
			closeButton.addEventListener('click', () => this.close())
		})

		this.showCorrectOptionStock()
		this.closeButton.addEventListener('click', () => this.close())
	}

	countdown() {
		this.interval = setInterval(() => {
			this.countdownFrom--
			this.counter.innerText = this.countdownFrom
			if (this.countdownFrom === this.countdownTo) {
				clearInterval(this.interval)
				this.close()
			}
		}, 1000)
	}

	showCorrectOptionStock() {
		this.sizeBox.innerText = this.sizeName
		this.stockLists.forEach(stockList => {
			if (stockList.getAttribute('data-option-id') === this.optionId) {
				stockList.classList.add('popup-stock-locations__option--active')
				return
			}

			stockList.classList.remove('popup-stock-locations__option--active')
		})
	}

	open() {
		// clearInterval(this.interval)
		this.element.classList.remove('popup--hidden')
		document.querySelector('.overlay--main').classList.remove('overlay--hidden')
	}

	close() {
		// clearInterval(this.interval)
		this.element.classList.add('popup--hidden')
		document.querySelector('.overlay--main').classList.add('overlay--hidden')
	}
}
