import { Block } from 'webiq.boilerplate'
import StockLocationsPopup from './StockLocationsPopup'
import queryString from 'query-string'

export default class ProductInfo extends Block {
	static title = 'product-info'

	init() {
		console.log(this)
		this.shoppingcartPopup = document.querySelector('.shoppingcart-popup')

		this.colorRadios = Array.from(this.element.querySelectorAll('input.color__input'))
		this.colorRadios.forEach(radio => {
			radio.addEventListener('change', () => {
				this.isB2b ? this.colorSwitchB2b(radio) : this.colorSwitch(radio)
			})
		})

		this.sizeBoxes = Array.from(this.element.querySelectorAll('.size'))
		this.sizeRadios = Array.from(this.element.querySelectorAll('input.size__input'))
		this.sizeRadios.forEach(radio => {
			radio.addEventListener('change', () => this.sizeSwitch(radio))
		})

		this.b2bSizeBoxes = Array.from(this.element.querySelectorAll('.product-info__number'))
		this.isB2b = this.element.classList.contains('product-info--b2b')

		// if (!this.isB2b && (!this.colorRadios.length || !this.sizeRadios.length)) {
		// 	return
		// }

		this.priceTag =
			document.querySelector('.product-info__price__call-to-action') ||
			document.querySelector('.product-info__price__highlight')

		this.addToCartButton = this.element.querySelector('.product-info__button--add-to-cart')
		this.messageBox = this.element.querySelector('.product-info__message-product-add')

		this.stockLocationsButton = this.element.querySelector('.product-info__button--stock-locations')
		this.stockLocationsPopup = document.querySelector('.popup-stock-locations')

		this.mainImage = document.querySelector('.product-image--zoom')
		this.imageGalleries = document.querySelectorAll('.product-image-gallery')

		this.wishlist = this.element.querySelector('.wishlist')

		this.productId = this.element.getAttribute('data-product-id')
		this.selectedColorId = this.element.querySelector('input[name="color_id"]').value

		this.selectedColorName = this.element.querySelector('input[name="color_id"]').getAttribute('data-color-name')
		this.selectedSizeId = null
		this.selectedSizeName = null
		this.selectedEan = null

		if (this.addToCartButton && !this.isB2b) {
			this.addToCartButton.addEventListener('click', event => this.submitAddForm(event))
		}

		if (this.stockLocationsButton && this.stockLocationsPopup) {
			this.stockLocationsButton.addEventListener('click', event => this.openStockPopup(event))
		}

		const parsedQueryString = queryString.parse(location.search)
		if (parsedQueryString.size_id) {
			this.selectSize(parsedQueryString.size_id)
		}

		this.countElement = this.element.querySelector('.product-info__number__input')
		this.countPlus = this.element.querySelector('.product-info__number__button[data-plus]')
		if (this.countPlus) {
			this.countPlus.addEventListener('click', () => {
				this.countElement.value = parseInt(this.countElement.value) + 1
			})
		}
		this.countMin = this.element.querySelector('.product-info__number__button[data-min]')
		if (this.countMin) {
			this.countMin.addEventListener('click', () => {
				this.countElement.value = parseInt(this.countElement.value) - 1
			})
		}

		if (this.element.dataset.noOptions == 'true') {
			this.selectedSizeId = 0
			this.selectedColorId = 0
		}
	}

	openStockPopup(event) {
		event.preventDefault()

		if (!this.selectedColorId || !this.selectedSizeId) {
			this.messageBox.classList.add('product-info__message-product-add--active')
			return
		}

		const optionId = `${this.selectedSizeId}_${this.selectedColorId}`
		this.stockLocationsPopup.ref = new StockLocationsPopup(
			this.stockLocationsPopup,
			optionId,
			this.selectedSizeName,
			this.selectedColorName
		)

		this.stockLocationsPopup.ref.open()
	}

	// on selection of another color
	colorSwitch(radioElement) {
		this.selectedColorId = radioElement.value

		this.sizeRadios.forEach(sizeRadio => {
			sizeRadio.checked = false
		})

		this.showSizeBoxes(radioElement.value)
		this.showImageGallery(radioElement.value)
	}

	// on selection of another color
	colorSwitchB2b(radioElement) {
		this.selectedColorId = radioElement.value

		this.showB2bSizeBoxes(radioElement.value)
		this.showImageGallery(radioElement.value)
	}

	sizeSwitch(radioElement) {
		this.selectedSizeId = radioElement.value
		this.selectedSizeName = radioElement.getAttribute('data-size-name')
		this.selectedEan = radioElement.getAttribute('data-ean')
		this.updateWishlistData()

		if (radioElement.getAttribute('data-option-price') > 0 && this.priceTag) {
			this.priceTag.textContent = `€ ${radioElement.getAttribute('data-option-price')}`
		}

		if (radioElement.getAttribute('data-stock') > 0) {
			this.enableBuyButton()
			return
		}

		this.disableBuyButton()
	}

	// show size boxes by color id
	showSizeBoxes(colorId) {
		this.sizeBoxes.forEach(sizeBox => {
			if (sizeBox.dataset.colorId == colorId) {
				sizeBox.classList.remove('size--hidden')
				return
			}

			sizeBox.classList.add('size--hidden')
		})
	}

	// show size boxes by color id
	showB2bSizeBoxes(colorId) {
		this.b2bSizeBoxes.forEach(sizeBox => {
			if (sizeBox.dataset.colorId == colorId) {
				sizeBox.classList.remove('product-info__number--hidden')
				return
			}

			sizeBox.classList.add('product-info__number--hidden')
		})
	}

	showImageGallery(colorId) {
		this.imageGalleries.forEach(imageGallery => {
			if (imageGallery.dataset.colorId == colorId) {
				imageGallery.ref.viewGallery()
				return
			}

			imageGallery.ref.hideGallery()
		})
	}

	// check values before submitting
	submitAddForm(event) {
		event.preventDefault()
		if ((!this.selectedColorId || !this.selectedSizeId) && this.element.dataset.noOptions != 'true') {
			this.messageBox.classList.add('product-info__message-product-add--active')
			return
		}

		let payload = new FormData()
		payload.append('size_id', this.selectedSizeId)
		payload.append('color_id', this.selectedColorId)
		if (this.countElement) {
			payload.append('count', this.countElement.value)
		} else {
			payload.append('count', 1)
		}
		payload.append('product_id', this.productId)
		payload.append('ajax', true)

		payload.append('add', true)

		fetch('/shoppingcart', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
			headers: {
				ContentType: 'multipart/form-data',
			},
		})
			.then(response => response.json())
			.then(json => this.handleAddResponse(json))
	}

	handleAddResponse(json) {
		if (json.error) {
			return
		}

		if (this.shoppingcartPopup) {
			this.shoppingcartPopup.ref.update(json)
			this.shoppingcartPopup.ref.toggle()
		}

		this.googleEcommerceSend()
		this.facebookPixelSend()
	}

	facebookPixelSend() {
		if (typeof fbq === 'undefined') {
			return
		}

		let payload = {
			content_ids: [this.selectedEan],
			content_type: 'product',
			value: this.element.getAttribute('data-price'),
			currency: 'EUR',
		}

		fbq('track', 'AddToCart', payload)
	}

	googleEcommerceSend() {
		if (typeof ga === 'undefined') {
			return
		}

		let payload = {
			id: this.selectedEan,
			name: this.element.getAttribute('data-name'),
			category: this.element.getAttribute('data-category'),
			brand: this.element.getAttribute('data-brand'),
			variant: this.selectedColorName,
			price: this.element.getAttribute('data-price'),
			quantity: 1,
		}

		ga('ec:addProduct', payload)

		ga('ec:setAction', 'add')
		ga('send', 'event', 'UX', 'click', 'Add To Cart')
	}

	updateWishlistData() {
		if (!this.wishlist || !this.wishlist.ref) {
			return
		}

		this.wishlist.querySelector('.wishlist__color-value').value = this.element.querySelector(
			'input[name="color_id"]:checked'
		).value
		this.wishlist.querySelector('.wishlist__size-value').value = this.element.querySelector(
			'input[name="size_id"]:checked'
		).value
	}

	disableBuyButton() {
		if (!this.addToCartButton) {
			return
		}

		this.addToCartButton.disabled = true
		this.addToCartButton.classList.add('button--out-of-stock')
	}

	enableBuyButton() {
		if (!this.addToCartButton) {
			return
		}

		this.addToCartButton.disabled = false
		this.addToCartButton.classList.remove('button--out-of-stock')
	}

	selectSize(sizeId) {
		let colorId = document.querySelector('.color__input:checked').value
		let sizeInput = document.querySelector(`.size__input[data-option-id="${sizeId}_${colorId}"]`)

		if (sizeInput) {
			if (sizeInput.getAttribute('data-stock') > 0) {
				sizeInput.checked = true
				this.sizeSwitch(sizeInput)
			}
		}
	}
}
